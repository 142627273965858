import React, {useCallback, useEffect} from 'react';
import { Modal } from 'react-bootstrap';
import {useFieldArray, useForm} from "react-hook-form";
import SelectBox from "../../components/SelectBox/SelectBox";
import {fetchPropertyUsers, updateContactForProperty} from "../../services/dashBoardServices";

const ReAssignSchedule = ({ showModal,properties,onHide,toDeleteUserId }) => {
    const { register, watch,control, handleSubmit, formState: { errors } } = useForm({
        defaultValues:[]
    });
    const { fields, append,remove } = useFieldArray({
        control,
        name: "items"
    });

    const items = watch('items');

    useEffect(() => {
        console.log(toDeleteUserId+' toDeleteUserId');
        remove();
        properties.forEach((property) => {
            fetchPropertyUsers(property.id).then((res) => {
                const userList = res.userList.filter((user) => user.id !== toDeleteUserId);
                append({ name: property.propertyName, id: property.id, userId:'',users:userList});
            })
        });
    }, [properties]);
    const closeUserModal = useCallback(
        (reload = false) => {
            onHide(reload);
            remove();
        },
        [onHide]
    );
    const onSubmit = async (data) => {
        const {items} = data;
        let error = false;
        for (let item of items) {
            const res = await updateContactForProperty(item.id, {newUserId: item.userId, userId: toDeleteUserId});
            error = res.error;
        }
        if (!error) {
            closeUserModal(true);
        }
    }
    return (
        <>
            <Modal
                show={showModal}
                centered
                onHide={onHide}
            >
                <Modal.Header>
                    <h3 className="font-weight-bold mb-0">Re-assign User</h3>
                </Modal.Header>
                <Modal.Body>

                    <h5 className="font-weight-bold mb-4">Select the user against each property to re-assign</h5>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        {fields.map((field, index) => (
                            <div key={field.id} className="mb-4" style={{display:"flex" ,justifyContent: "space-between"}}>
                                <label  className="form-label" style={{paddingRight:10}}>{items[index]?.name} <span
                                    className="text-danger">*</span></label>
                                <select
                                    type="select"
                                    {...register(`items.${index}.userId`, {
                                        required: "Selection is required"
                                    })}
                                    placeholder="User"
                                >
                                    {items[index]?.users?.map((user) => {

                                    return <option label={`${user.firstName} ${user.lastName}`} value={user.id}></option>;
                                })}
                                </select>
                            </div>
                        ))}
                        <div className="d-flex justify-content-end align-items-center">
                            <button
                                className="btn btn-outline-dark px-5 me-3"
                                type="button"
                                onClick={() => {
                                    closeUserModal();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-primary px-5"
                                type="submit"
                                autoFocus={true}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="pt-0 pb-4">
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ReAssignSchedule;
