import api from '../config/apiBase';
import axios from 'axios';

export const fetchProperty = propertyId => {
  return api()
    .get(`/callSettings/${propertyId}`)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const fetchWeeklySettings = propertyId => {
  return api()
    .get(`/callSettingsRolling/byPropertyId/${propertyId}`)
    .then(res => handleResponsonse(res))
    .catch(err => handleError(err));
};

export const fetchPropertiesWithUserAsContact = userId => {
  return api()
      .get(`/callSettingsRolling/propsforuser/${userId}`)
      .then(res => handleResponsonse(res))
      .catch(err => handleError(err));
};


export const updateContactForProperty = (propertyId,data) => {
  return api()
      .post(`callSettingsRolling/update-contact-for-property/${propertyId}`,data)
      .then(res => res.data)
      .catch(err => handleError(err));
};

export const updateProperty = property => {
  return api()
    .post('/callSettings', property)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const updateWeeklySettings = property => {
  return api()
    .post('/callSettingsRolling', property)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const fetchCallsList = callsListSpec => {
  return api()
    .post('/callLogs/search', callsListSpec)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const fetchCallLogDetails = id => {
  return api()
    .get(`/callLogs/${id}`)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const updateAction = (id, actionSpec) => {
  return api()
    .post(`/callLogs/${id}`, actionSpec)
    .then(res => res.data)
    .catch(err => handleError(err));
};

//
export const openCloseMultipleJobs = (updateAction, callEntryIds) => {
  return api()
    .post(`/callLogs/openClose`, {
      updateAction,
      callEntryIds,
    })
    .then(res => handleResponsonse(res))
    .catch(err => handleError(err));
};

export const loggedInUser = () => {
  return api()
    .get('/users/loggedInUser')
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const fetchPropertyList = userId => {
  return api()
    .get(`/property/findByUserId/${userId}`)
    .then(res => res.data)
    .catch(err => handleError(err));
};

// Creating users and listing users

export const fetchUsersList = userListSpec => {
  return api()
    .post(`/users/search`, userListSpec)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const addUser = userDetails => {
  return api()
    .post(`/users`, userDetails)
    .then(res => res.data)
    .catch(err => (handleError(err), err.response));
};

export const deleteUser = id => {
  return api()
    .delete(`/users/${id}`)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const updateUser = (id, updateSpec) => {
  return api()
    .put(`/users/${id}`, updateSpec)
    .then(res => res.data)
    .catch(err => (handleError(err), err.response));
};

// Creating properties and listing properties
export const fetchPropList = propertyListSpec => {
  return api()
    .post(`/property/search`, propertyListSpec)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const createProperty = propertyListSpec => {
  return api()
    .post(`/property`, propertyListSpec)
    .then(res => res.data)
    .catch(err => (handleError(err), err.response));
};

export const deleteProperty = id => {
  return api()
    .delete(`/property/${id}`)
    .then(res => res.data)
    .catch(err => (handleError(err), err.response));
};

export const editProperty = (id, updateSpec) => {
  return api()
    .put(`/property/${id}`, updateSpec)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const updateCourtesyOfficer = (id, selectedCourtesyOfficer) => {
  return api()
      .post(`/property/updateCourtesyOfficer/${id}`, selectedCourtesyOfficer)
      .then(res => res.data)
      .catch(err => handleError(err));
};

export const maskPhoneNumber = (fromNumber, toNumber, callEntryId) => {
  return api()
    .post(`/callTree/callMaskNumber`, {
      fromNumber,
      toNumber,
      callEntryId,
    })
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const fetchPropertyUsers = propertyId => {
  return api()
    .get(`users/byPropertyId/${propertyId}`)
    .then(res => res.data)
    .catch(err => handleError(err));
};


export const fetchResmanPropertyList = () => {
  return api()
    .post(`smartfuse/property`, { target: 'resman' })
    .then(res => res.data.data)
    .catch(err => handleError(err));
};

export const updateCallTask = (callId, callTaskDetail) => {
  return api()
    .put(`callLogs/${callId}`, callTaskDetail)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const fetchResmanUnits = selectedProp => {
  return api()
    .post(`smartfuse/unit`, selectedProp)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const syncResmanUnits = selectedProp => {
  return api()
    .post(`smartfuse/loadUnits`, selectedProp)
    .then(res => res.data)
    .catch(err => handleError(err));
};

export const fetchNotificationConfig = userId => {
  return api()
    .get(`users/notificationConfig/${userId}`)
    .then(res => handleResponsonse(res))
    .catch(err => handleError(err));
};

export const updateNotificationConfig = notificationConfig => {
  return api()
    .post('users/notificationConfig', notificationConfig)
    .then(res => handleResponsonse(res))
    .catch(err => handleError(err));
};

export const fetchPropertyStats = searchRequest => {
  return api()
      .post('report/propertyStats', searchRequest)
      .then(res => res)
      .catch(err => handleError(err));
};

export const inviteUser = userInviteDetails => {
  return api()
    .post(`/users/invite`, userInviteDetails)
    .then(res => res.data)
    .catch(err => (handleError(err), err.response));
};

export const validateUserInvitationToken = invitationToken => {
  return api()
      .get(`/usersRegistration/validate-token/${invitationToken}`)
      .then(res => handleResponsonse(res))
      .catch(err => (handleError(err), err.response));
};

export const registerUser = (invitationToken, userDetails) => {
  return api()
    .post(`/usersRegistration/signup/${invitationToken}`, userDetails)
    .then(res => handleResponsonse(res))
    .catch(err => (handleError(err), err.response));
};

const handleResponsonse = res => ({
  data: res.data,
  status: res.status,
  error: false,
});

const handleError = error => {
  if (axios.isAxiosError(error)) {
    return {
      status: error.response.status,
      error: true,
      ...error.response.data,
    };
  } else {
    return {
      status: 0,
      error: true,
      message: 'Something went wrong. Please try again later.',
    };
  }
};
